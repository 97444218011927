var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-select", {
    attrs: {
      value: _vm.value,
      loading: _vm.loading,
      disabled: _vm.disabled,
      "filter-option": false,
      "show-search": "",
      "allow-clear": "",
      options: _vm.options
    },
    on: { change: _vm.onChange, search: _vm.onSearch }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }