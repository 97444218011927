


















import { debounceProcess } from "@/helpers/debounce";
import { useContactData } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { LabelInValue } from "@/types";
import { ListContactDataDto } from "@interface/contact-data";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectSales extends Vue {
  @Prop({
    required: true,
    default: undefined,
  })
  value!: LabelInValue | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<ListContactDataDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  fetchOptions(params: RequestQueryParams = new RequestQueryParams()): void {
    const { findSales, toOptions } = useContactData();
    this.loading = true;
    findSales(params)
      .then(res => {
        this.options = toOptions(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: LabelInValue | undefined): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { filterBy } = useContactData();
    const params = new RequestQueryParams();
    params.search = filterBy({ firstName: val, lastName: val });

    this.fetchOptions(params);
  }

  findOption(value?: LabelInValue): Option | undefined {
    if (!value) return value;
    return this.options.find(e => value.key === e.value);
  }
}
