







































import {
  FormHeader,
  FormTableAdjustment,
  FormTableOpname,
} from "@/components/StockAdjustment";
import { useStockAdjustment } from "@/hooks/useStockAdjustment";
import { StockAdjustmentMapper } from "@/mapper/StockAdjustment.mapper";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import {
  FormValue,
  StockAdjRow,
  StockAdjustmentRequestCreateDTO,
} from "@/models/interface/stock-adjustment";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";

const NEW_DOC_TO_SUBMIT_FLAG = "-";

/**
 * STOCK ADJUSTMENT
 * fields
 * - rack
 * - type adjustment = stock adjustment / opname
 * - date
 * - notes
 * - attachment
 *
 * lines
 * - part number (master product)
 * - part name (master product)
 * - merk (merk product) [disabled]
 * - qty adjustment (input user)
 * - uom
 *
 * kolom qty adjustment dimapping ke `qty` dari input user
 */

/**
 * STOCK OPNAME
 * fields
 * - rack
 * - type adjustment = stock adjustment / opname
 * - date
 * - part category
 * - merk
 * - notes
 * - attachment
 *
 * lines
 * - part number (product inventory)
 * - part name (product inventory)
 * - merk (product merk) [disabled]
 * - qty total (qty on hand inventory) [disabled]
 * - uom
 * - qty fisik (input user)
 * - qty diff (kalkulasi) [disabled]
 *
 * kolom qty total diambil dari `onHand` inventory. dimmaping ke `qty`
 * kolom qty diff ambil dari perhitungan
 * kolom part number dan part name ngambil dari inventory berdasarkan
 *  - rack
 *  - product category
 *  - merk
 */

@Component({
  beforeRouteLeave(to, from, next) {
    this.resetStore();
    next();
  },
  components: {
    FormHeader,
    FormTableAdjustment,
    FormTableOpname,
  },
  computed: {
    ...mapGetters({
      isOpname: "stockAdjStore/isOpname",
      isAdjustment: "stockAdjStore/isAdjustment",
      form: "stockAdjStore/getForm",
      allowUpdate: "stockAdjStore/allowUpdate",
    }),
  },
  methods: {
    ...mapActions({
      resetStore: "stockAdjStore/resetStore",
      getDetailDoc: "stockAdjStore/getDetailDoc",
    }),
  },
})
export default class FormPage extends Mixins(MNotificationVue) {
  isOpname!: boolean;
  isAdjustment!: boolean;
  allowUpdate!: boolean;
  form!: FormValue;
  resetStore!: () => void;
  getDetailDoc!: (docId: string) => void;

  @Ref("formHeader")
  formHeader!: any;

  @Prop({ required: false, type: String, default: "" })
  id!: string; // stock adj secure id

  loading = {
    saveDraft: false,
    submit: false,
    update: false,
  };

  get title(): string {
    const mode = this.$route.meta?.mode || "";
    const createStr = this.$t("lbl_create_x", {
      x: this.$t("lbl_stock_adjustment"),
    });
    const editStr = this.$t("lbl_edit_x", {
      x: this.$t("lbl_stock_adjustment"),
    });

    return mode === Mode.CREATE ? createStr.toString() : editStr.toString();
  }

  get isModeCreate(): boolean {
    return this.$route.meta?.mode === Mode.CREATE;
  }

  get formTable(): "FormTableOpname" | "FormTableAdjustment" {
    return this.isOpname ? "FormTableOpname" : "FormTableAdjustment";
  }

  hasDuplicateSn(state: StockAdjRow[]): boolean {
    const snList = state
      .filter(row => row.serialNumber)
      .map(row => row.serialNumber);
    return snList.some((sn, index) => snList.indexOf(sn) !== index);
  }

  validateForm(action: "update" | "submit"): void {
    const { isRowsInvalid } = useStockAdjustment();
    const form: FormModel = this.formHeader.formModel;
    form.validate(valid => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      if (isRowsInvalid(this.form.stockAdjustmentRows)) {
        this.showNotifWarning("notif_stock_adjustment_rows_invalid");
        return;
      }

      if (this.hasDuplicateSn(this.form.stockAdjustmentRows)) {
        this.showNotifWarning("notif_stock_adjustment_rows_duplicate_sn");
        return;
      }

      if (action === "update") {
        this.handleUpdate(this.id);
      } else if (action === "submit") {
        this.handleSubmit(this.id || NEW_DOC_TO_SUBMIT_FLAG);
      }
    });
  }

  handleSubmit(docId: string): void {
    const { submit } = useStockAdjustment();
    const req: StockAdjustmentRequestCreateDTO =
      StockAdjustmentMapper.mapFormToCreateDto(this.form);

    this.loading.submit = true;
    submit(docId, req)
      .then(({ documentNumber, id }) => {
        this.showNotifSuccess("notif_submit_success", {
          documentNumber,
        });
        this.goToDetail(id);
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  goToDetail(docId: string): void {
    this.$router.push({
      name: "logistic.stock-adjustment.detail",
      params: {
        id: docId,
      },
    });
  }

  handleUpdate(docId: string): void {
    const { update } = useStockAdjustment();
    const req: StockAdjustmentRequestCreateDTO =
      StockAdjustmentMapper.mapFormToCreateDto(this.form);
    this.loading.update = true;
    update(docId, req)
      .then(({ documentNumber }) => {
        this.showNotifSuccess("notif_update_success", {
          documentNumber,
        });
        this.getDetailDoc(docId);
      })
      .finally(() => {
        this.loading.update = false;
      });
  }

  handleSaveDraft(): void {
    const { createDraft } = useStockAdjustment();
    const req: StockAdjustmentRequestCreateDTO =
      StockAdjustmentMapper.mapFormToCreateDto(this.form);
    this.loading.saveDraft = true;
    createDraft(req)
      .then(({ documentNumber }) => {
        this.showNotifSuccess("notif_create_success", {
          documentNumber,
        });
        this.resetForm();
      })
      .finally(() => {
        this.loading.saveDraft = false;
      });
  }

  handeBack(): void {
    this.$router.push({
      name: "logistic.stock-adjustment",
    });
  }

  resetForm(): void {
    const form: FormModel = this.formHeader.formModel;
    form.resetFields();
    this.resetStore();
  }
}
