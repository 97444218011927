var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      columns: _vm.getColumns,
      "data-source": _vm.store.soLines,
      pagination: {
        showTotal: function(total) {
          return _vm.$t("lbl_total_items", { total: total })
        },
        showSizeChanger: true
      },
      scroll: { x: "calc(1200px + 100%)" },
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      },
      size: "small"
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _c(
              "div",
              [
                _c(
                  "a-space",
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.isSalesRent || _vm.isSubmitted
                        },
                        on: { click: _vm.addRow }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                    ),
                    _c(
                      "a-button",
                      {
                        attrs: {
                          type: "danger",
                          disabled: _vm.isSalesRent || _vm.isSubmitted
                        },
                        on: { click: _vm.handleDeleteRow }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "currency",
        fn: function(text) {
          return _c("span", {}, [
            _vm._v(" " + _vm._s(_vm._f("currency")(text)) + " ")
          ])
        }
      },
      {
        key: "number",
        fn: function(text) {
          return _c("span", {}, [
            _vm._v(" " + _vm._s(_vm._f("qty")(text)) + " ")
          ])
        }
      },
      {
        key: "nullable",
        fn: function(text) {
          return _c("span", {}, [_vm._v(" " + _vm._s(text || "-") + " ")])
        }
      },
      {
        key: "productCode",
        fn: function(text, row) {
          return [
            _vm.isAssetSale
              ? _c("SelectAvailableAsset", {
                  staticClass: "w-100",
                  attrs: {
                    "branch-id": _vm.store.form.branchId,
                    disabled: _vm.isSubmitted
                  },
                  on: {
                    "update:meta": function($event) {
                      return _vm.onChangeAsset($event, row)
                    }
                  },
                  model: {
                    value: row.unitCode,
                    callback: function($$v) {
                      _vm.$set(row, "unitCode", $$v)
                    },
                    expression: "row.unitCode"
                  }
                })
              : _vm._e(),
            _vm.isSalesProductSale
              ? _c("SelectProductInStock", {
                  staticClass: "w-100",
                  attrs: { disabled: _vm.isSubmitted, "option-label": "code" },
                  on: {
                    "update:meta": function($event) {
                      return _vm.onChangeProduct($event, row)
                    }
                  },
                  model: {
                    value: row.productCode,
                    callback: function($$v) {
                      _vm.$set(row, "productCode", $$v)
                    },
                    expression: "row.productCode"
                  }
                })
              : _vm._e(),
            _vm.isSalesOther
              ? _c("SelectProductService", {
                  staticClass: "w-100",
                  attrs: { disabled: _vm.isSubmitted, "option-label": "code" },
                  on: {
                    "update:meta": function($event) {
                      return _vm.onChangeProduct($event, row)
                    }
                  },
                  model: {
                    value: row.productCode,
                    callback: function($$v) {
                      _vm.$set(row, "productCode", $$v)
                    },
                    expression: "row.productCode"
                  }
                })
              : _vm.isSalesRent
              ? _c("span", [
                  _vm._v(
                    " " + _vm._s(row.unitCode || row.productCode || "-") + " "
                  )
                ])
              : _vm._e()
          ]
        }
      },
      {
        key: "productName",
        fn: function(text, row) {
          return [
            _vm.isAssetSale
              ? _c("span", [_vm._v(" " + _vm._s(row.unitCode) + " ")])
              : _vm.isSalesRent
              ? _c("span", [
                  _vm._v(
                    " " + _vm._s(row.unitCode || row.productName || "-") + " "
                  )
                ])
              : _vm.isSalesProductSale
              ? _c("SelectProductInStock", {
                  staticClass: "w-100",
                  attrs: { "option-label": "name", disabled: _vm.isSubmitted },
                  on: {
                    "update:meta": function($event) {
                      return _vm.onChangeProduct($event, row)
                    }
                  },
                  model: {
                    value: row.productName,
                    callback: function($$v) {
                      _vm.$set(row, "productName", $$v)
                    },
                    expression: "row.productName"
                  }
                })
              : _vm.isSalesOther
              ? _c("SelectProductService", {
                  staticClass: "w-100",
                  attrs: { disabled: _vm.isSubmitted, "option-label": "name" },
                  on: {
                    "update:meta": function($event) {
                      return _vm.onChangeProduct($event, row)
                    }
                  },
                  model: {
                    value: row.productName,
                    callback: function($$v) {
                      _vm.$set(row, "productName", $$v)
                    },
                    expression: "row.productName"
                  }
                })
              : _vm._e()
          ]
        }
      },
      {
        key: "serialNumber",
        fn: function(text, row) {
          return [
            _vm.isAssetSale || _vm.isSalesRent
              ? _c("span", [_vm._v(" " + _vm._s(text || "-") + " ")])
              : _c("SelectSnSales", {
                  staticClass: "w-100",
                  attrs: {
                    parameter: {
                      branchId: _vm.store.form.branchId,
                      productId: row.productId
                    },
                    disabled: _vm.isSubmitted
                  },
                  model: {
                    value: row.serialNumber,
                    callback: function($$v) {
                      _vm.$set(row, "serialNumber", $$v)
                    },
                    expression: "row.serialNumber"
                  }
                })
          ]
        }
      },
      {
        key: "location",
        fn: function(text, row) {
          return [
            _vm.isSalesProductSale || _vm.isSalesOther
              ? _c("SelectLocationInventory", {
                  staticClass: "w-100",
                  attrs: {
                    parameter: {
                      productId: row.productId,
                      branchId: _vm.store.form.branchId,
                      uomId: row.uomId
                    },
                    disabled: _vm.isSubmitted
                  },
                  on: {
                    "update:meta": function($event) {
                      return _vm.onChangeInventory($event, row)
                    }
                  },
                  model: {
                    value: row.locationName,
                    callback: function($$v) {
                      _vm.$set(row, "locationName", $$v)
                    },
                    expression: "row.locationName"
                  }
                })
              : _c("span", [_vm._v(" " + _vm._s(text || "-") + " ")])
          ]
        }
      },
      {
        key: "taxCode",
        fn: function(text, row) {
          return [
            _c("SelectTaxVatOut", {
              staticClass: "w-100",
              attrs: {
                disabled: _vm.isTaxNone || _vm.isSubmitted,
                "label-in-value": ""
              },
              on: {
                "update:meta": function($event) {
                  return _vm.onChangeTaxCode($event, row)
                }
              },
              model: {
                value: row.taxCode,
                callback: function($$v) {
                  _vm.$set(row, "taxCode", $$v)
                },
                expression: "row.taxCode"
              }
            })
          ]
        }
      },
      {
        key: "backupUnit",
        fn: function(text, row) {
          return [
            _c("DisplayBoolean", {
              attrs: { value: row.backupUnit, mode: "yes" }
            })
          ]
        }
      },
      {
        key: "qty",
        fn: function(text, row) {
          return _c("a-input-number", {
            staticClass: "w-100",
            attrs: {
              formatter: _vm.formatterNumber,
              parser: _vm.reverseFormatNumber,
              precision: _vm.storeBaseDecimalPlace,
              min: 0,
              disabled: _vm.isSalesRent || _vm.isSubmitted
            },
            on: { change: _vm.calcSoLines },
            model: {
              value: row.qty,
              callback: function($$v) {
                _vm.$set(row, "qty", _vm._n($$v))
              },
              expression: "row.qty"
            }
          })
        }
      },
      {
        key: "price",
        fn: function(text, row) {
          return _c("a-input-number", {
            staticClass: "w-100",
            attrs: {
              formatter: _vm.formatterNumber,
              parser: _vm.reverseFormatNumber,
              precision: _vm.storeBaseDecimalPlace,
              min: 0,
              disabled: _vm.isSalesRent || _vm.isSubmitted
            },
            on: { change: _vm.calcSoLines },
            model: {
              value: row.price,
              callback: function($$v) {
                _vm.$set(row, "price", _vm._n($$v))
              },
              expression: "row.price"
            }
          })
        }
      },
      {
        key: "discount",
        fn: function(text, row) {
          return _c("a-input-number", {
            staticClass: "w-100",
            attrs: {
              formatter: _vm.formatterNumber,
              parser: _vm.reverseFormatNumber,
              precision: _vm.storeBaseDecimalPlace,
              min: 0,
              disabled: _vm.isSubmitted
            },
            on: { change: _vm.calcSoLines },
            model: {
              value: row.discountValue,
              callback: function($$v) {
                _vm.$set(row, "discountValue", _vm._n($$v))
              },
              expression: "row.discountValue"
            }
          })
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }