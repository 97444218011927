var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      size: "small",
      "data-source": _vm.form.stockAdjustmentRows,
      columns: _vm.columns,
      pagination: {
        showSizeChanger: true,
        showTotal: function(total) {
          return _vm.$t("lbl_total_items", { total: total })
        },
        pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
      },
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      },
      "row-class-name": function(_record, index) {
        return index % 2 ? "bg-white" : "bg-gray-light"
      },
      scroll: { x: 1500 }
    },
    scopedSlots: _vm._u([
      {
        key: "nullable",
        fn: function(text) {
          return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
        }
      },
      {
        key: "title",
        fn: function() {
          return [
            _c(
              "a-button-group",
              [
                _c("a-button", {
                  attrs: { icon: "plus", type: "primary" },
                  on: { click: _vm.addRow }
                }),
                _c("a-button", {
                  attrs: { icon: "delete", type: "danger" },
                  on: { click: _vm.handleDeleteRow }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "productCode",
        fn: function(text, row) {
          return [
            _c("SelectProduct", {
              staticClass: "w-100",
              attrs: { "label-in-value": "" },
              on: {
                "update:meta": function($event) {
                  return _vm.onChangeProduct($event, row)
                }
              },
              model: {
                value: row.productCode,
                callback: function($$v) {
                  _vm.$set(row, "productCode", $$v)
                },
                expression: "row.productCode"
              }
            })
          ]
        }
      },
      {
        key: "productName",
        fn: function(text, row) {
          return [
            _c("SelectProduct", {
              staticClass: "w-100",
              attrs: { "label-in-value": "", "option-label": "name" },
              on: {
                "update:meta": function($event) {
                  return _vm.onChangeProduct($event, row)
                }
              },
              model: {
                value: row.productName,
                callback: function($$v) {
                  _vm.$set(row, "productName", $$v)
                },
                expression: "row.productName"
              }
            })
          ]
        }
      },
      {
        key: "type",
        fn: function(text, row) {
          return [
            _c("a-select", {
              staticClass: "w-100",
              attrs: {
                options: [
                  {
                    label: "(+) " + _vm.$t("lbl_increment"),
                    value: "increment"
                  },
                  {
                    label: "(-) " + _vm.$t("lbl_decrement"),
                    value: "decrement"
                  }
                ],
                placeholder: _vm.$t("common.select-text", {
                  text: _vm.$t("lbl_type")
                })
              },
              on: {
                change: function($event) {
                  return _vm.onChangeType($event, row)
                }
              },
              model: {
                value: row.type,
                callback: function($$v) {
                  _vm.$set(row, "type", $$v)
                },
                expression: "row.type"
              }
            })
          ]
        }
      },
      {
        key: "qtyAdjustment",
        fn: function(text, row) {
          return [
            _c("a-input-number", {
              staticClass: "w-100",
              attrs: {
                formatter: _vm.formatterNumber,
                parser: _vm.reverseFormatNumber,
                precision: _vm.storeBaseDecimalPlace,
                max: row.maxQty,
                min: row.minQty
              },
              model: {
                value: row.qty,
                callback: function($$v) {
                  _vm.$set(row, "qty", _vm._n($$v))
                },
                expression: "row.qty"
              }
            })
          ]
        }
      },
      {
        key: "uom",
        fn: function(text, row) {
          return [
            _c("a-select", {
              staticClass: "w-100",
              attrs: {
                "allow-clear": "",
                "show-search": "",
                "label-in-value": "",
                placeholder: _vm.$t("common.select-text", {
                  text: _vm.$t("lbl_uom")
                }),
                options: row.uomOptions
              },
              model: {
                value: row.uom,
                callback: function($$v) {
                  _vm.$set(row, "uom", $$v)
                },
                expression: "row.uom"
              }
            })
          ]
        }
      },
      {
        key: "serialNumber",
        fn: function(text, row) {
          return [
            row.type === "decrement"
              ? _c("a-select", {
                  staticClass: "w-100",
                  attrs: {
                    options: row.serialNumberOptions,
                    loading: row.loadingSerialNumber,
                    placeholder: _vm.$t("common.select-text", {
                      text: _vm.$t("lbl_serial_number")
                    }),
                    "show-search": "",
                    "allow-clear": ""
                  },
                  model: {
                    value: row.serialNumber,
                    callback: function($$v) {
                      _vm.$set(row, "serialNumber", $$v)
                    },
                    expression: "row.serialNumber"
                  }
                })
              : _c("a-input", {
                  attrs: {
                    placeholder: _vm.$t("common.insert-text", {
                      text: _vm.$t("lbl_serial_number")
                    }),
                    "allow-clear": ""
                  },
                  model: {
                    value: row.serialNumber,
                    callback: function($$v) {
                      _vm.$set(row, "serialNumber", $$v)
                    },
                    expression: "row.serialNumber"
                  }
                })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }