















































































































import SearchBuilder from "@/builder/SearchBuilder";
import SelectProduct from "@/components/custom/select/SelectProduct.vue";
import useProduct from "@/hooks/useProduct";
import { useCountQtyDifference } from "@/hooks/useStockAdjustment";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { InventoryLineResponseDto } from "@/models/interface/inventory";
import {
  ProductDetailDto,
  ProductUomConversionDto,
} from "@/models/interface/master-product";
import { ProductPurchaseResponseDto } from "@/models/interface/ProductPurchaseResponse.interface";
import { FormValue, StockAdjRow } from "@/models/interface/stock-adjustment";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";
import SelectProductInventory from "./SelectProductInventory.vue";

@Component({
  components: {
    SelectProduct,
    SelectProductInventory,
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) => st.preferenceStore.baseDecimalPlace,
    }),
    ...mapGetters({
      form: "stockAdjStore/getForm",
    }),
  },
  methods: {
    ...mapActions({
      addRow: "stockAdjStore/addStockAdjRow",
      deleteRow: "stockAdjStore/deleteStockAdjRow",
    }),
  },
})
export default class FormTableOpname extends Mixins(MNotificationVue) {
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  useCountQtyDifference = useCountQtyDifference;

  form!: FormValue;
  addRow!: () => void;
  deleteRow!: (payload: Array<number>) => void;

  selectedRowKeys: Array<number> = [];

  columns = [
    {
      // user pilih dari master product
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      //   width: "20%",
      scopedSlots: { customRender: "productCode" },
    },
    {
      // user pilih dari master product
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      //   width: "20%",
      scopedSlots: { customRender: "productName" },
    },
    {
      title: this.$t("lbl_serial_number"),
      key: "serialNumber",
      dataIndex: "serialNumber",
      width: 200,
      scopedSlots: { customRender: "serialNumber" },
    },
    {
      // autofill dari product yang dipilih
      // statis
      title: this.$t("lbl_brand"),
      dataIndex: "productMerk",
      //   width: "20%",
      scopedSlots: { customRender: "nullable" },
    },
    {
      // qty inventory on hand
      // statis
      title: this.$t("lbl_qty_total"),
      dataIndex: "qty",
      //   width: "20%",
      scopedSlots: { customRender: "number" },
    },
    {
      // user input
      title: this.$t("lbl_uom"),
      key: "uom",
      //   width: "20%",
      scopedSlots: { customRender: "uom" },
    },
    {
      // user input
      title: this.$t("lbl_qty_physical"),
      dataIndex: "physicalQty",
      scopedSlots: { customRender: "physicalQty" },
    },
    {
      // dari perhitungan
      title: this.$t("lbl_qty_difference"),
      dataIndex: "differenceQty",
      scopedSlots: { customRender: "differenceQty" },
    },
  ];

  created(): void {
    if (!this.$route.params.id) return;
    this.initSerialNumberOptions();
  }

  @Watch("form.warehouseLocation", { deep: true })
  handleWarehouseLocationChange(): void {
    this.form.stockAdjustmentRows.forEach(row => {
      if (row.productCode?.key) {
        this.getSerialNumberOptions(row.productCode.key, row);
      }
    });
  }

  @Watch("form.isUseSerialNumber")
  handleUseSerialNumberChange(val: boolean): void {
    if (!val) {
      this.form.stockAdjustmentRows.forEach(row => {
        row.serialNumber = null;
        row.serialNumberOptions = [];
      });
    }
  }

  initSerialNumberOptions(): void {
    this.form.stockAdjustmentRows.forEach((row: StockAdjRow) => {
      this.getSerialNumberOptions(row.productCode?.key ?? "", row);
    });
  }

  getSerialNumberOptions(productId: string, row: StockAdjRow): void {
    const builder = new SearchBuilder();
    const queries: string[] = [];

    queries.push(builder.push(["productId", productId]).build());

    if (this.form.warehouseLocation?.key) {
      queries.push(
        builder.push(["locationId", this.form.warehouseLocation.key]).build()
      );
    }

    const params: RequestQueryParamsModel = {
      search: queries.join(SearchBuilder.AND),
    };
    row.loadingSerialNumber = true;
    this.getSerialNumberList(params)
      .then(response => {
        row.serialNumberOptions = response;
      })
      .catch(() => {
        row.serialNumberOptions = [];
      })
      .finally(() => {
        row.loadingSerialNumber = false;
      });
  }

  async getSerialNumberList(
    params?: RequestQueryParamsModel
  ): Promise<Option<ProductPurchaseResponseDto>[]> {
    try {
      const { findAllSnForSales } = useProduct();
      const response = await findAllSnForSales(params);
      return response.map(item => ({
        label: item.serialNumber,
        key: item.serialNumber,
        value: item.serialNumber,
        meta: item,
      }));
    } catch {
      return [];
    }
  }

  onSelectChange(keys: Array<number>): void {
    this.selectedRowKeys = keys;
  }

  onChangeProduct(
    e: Option<InventoryLineResponseDto> | undefined,
    row: StockAdjRow
  ): void {
    row.productCode = undefined;
    row.productName = undefined;
    row.productMerk = e?.meta?.product?.merk || "";
    row.uom = undefined;
    row.qty = 0;
    row.serialNumber = null;
    row.serialNumberOptions = [];

    if (e && e?.meta) {
      row.productCode = {
        label: e.meta.product.code,
        key: e.meta.product.id,
      };
      row.productName = {
        label: e.meta.product.name,
        key: e.meta.product.id,
      };
    }

    if (!e?.meta?.product?.id) return;
    this.getDetailProduct(
      e.meta.product.id,
      ({ baseUnit, baseUnitId, uomConversions }) => {
        if (baseUnit && baseUnitId) {
          row.uom = {
            key: baseUnitId,
            label: baseUnit,
          };
        }
        row.uomOptions = this.toUomOptions(uomConversions);
      }
    );

    if (this.form.isUseSerialNumber) {
      this.getSerialNumberOptions(row.productCode?.key ?? "", row);
    } else {
      row.qty = e.meta.onHand ?? 0;
    }
  }

  getDetailProduct(id: string, cb: (payload: ProductDetailDto) => void): void {
    const { findById } = useProduct();
    findById(id).then(cb);
  }

  handleDeleteRow(): void {
    this.showConfirmationDeleteItems(() => {
      this.deleteRow(this.selectedRowKeys);
      this.selectedRowKeys = [];
    });
  }

  toUomOptions(
    uomConversions: ProductUomConversionDto[]
  ): Option<ProductUomConversionDto>[] {
    return uomConversions.map(item => ({
      key: item.uomSourceId,
      label: item.uomSource,
      value: item.uomSourceId,
      meta: item,
    }));
  }

  onChangeSerialNumber(row: StockAdjRow, value?: string): void {
    const option = row.serialNumberOptions.find(item => item.value === value);
    row.qty = option?.meta?.qtyAvailable || 0;
  }
}
